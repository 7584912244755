<template>
  <el-header>
    <img src="../public/logo.png" alt="" @click="linkUrl"/>
    <!--<router-link to="/">首页</router-link>-->
  </el-header>
  <el-page-header @back="goBack" v-show="showPageHeader" :icon="ArrowLeft" title="返回">
    <template #content>
      <span class="text-large font-600 mr-3"> {{ pageName }} </span>
    </template>
  </el-page-header>
  <router-view />
</template>

<script>
import { ArrowLeft } from '@element-plus/icons-vue'
import { shallowRef } from 'vue';
export default {
  data() {
    return {
      ArrowLeft: shallowRef(ArrowLeft),
      pageName: this.$route.name,
      showPageHeader: this.$route.path == '/'?false:true
    };
  },
  watch: {
    $route(to) {
      this.pageName = to.name;
      if(to.path == '/') {
        this.showPageHeader = false
      } else {
        this.showPageHeader = true
      }
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    linkUrl(){ this.$router.push('/') }
  },
};
</script>

<style lang="scss">
html {
  width: 100vw;
  // filter: grayscale(100%);
  // -moz-filter: grayscale(100%);
  // -o-filter: grayscale(100%);
  // -webkit-filter: grayscale(1);
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

.el-header {
  text-align: left;
  display: flex;
  align-items: center;
  box-shadow: var(--el-box-shadow-light);

  img {
    height: calc(var(--el-header-height) - 15px);
    margin-right: 20px;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    color: var(--el-color-primary);
  }
}

.el-page-header {
  padding: 0 20px;
}
::selection {
  background: var(--el-color-primary);
  color: var(--el-color-white);
}

::-moz-selection {
  background: var(--el-color-primary);
  color: var(--el-color-white);
}

::-webkit-selection {
  background: var(--el-color-primary);
  color: var(--el-color-white);
}
</style>
