<template>
  <el-main class="home">

    <el-space wrap size="large" data-inOut>
      <el-card shadow="always" :data-url="'onlineQuery'" @click="linkUrl">
        <el-icon>
          <Search />
        </el-icon>
        <el-space direction="vertical" alignment="flex-start">
          <span class="title">在线查询</span>
          <span class="description">账号关联信息查询</span>
        </el-space>
      </el-card>
      <el-card shadow="always" data-url="parsingVideo" @click="linkUrl">
        <el-icon>
          <Film />
        </el-icon>
        <el-space direction="vertical" alignment="flex-start">
          <span class="title">视频解析</span>
          <span class="description">各平台视频去水印</span>
        </el-space>
      </el-card>
      <el-card shadow="always" data-url="deDuplication" @click="linkUrl">
        <el-icon>
          <Files />
        </el-icon>
        <el-space direction="vertical" alignment="flex-start">
          <span class="title">数据拆分</span>
          <span class="description">数据拆分去重</span>
        </el-space>
      </el-card>
    </el-space>

  </el-main>
</template>

<script>
import { findEl } from '../utils/utils'
export default {
  name: "HomeView",
  data() {
    return {};
  },
  methods: {
    linkUrl({ target }) {
      this.$router.push(
        `/${findEl(target, "classList", "el-card").dataset.url}`
      );
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 980px) {
  .el-space[data-inOut] {
    margin-left: 8px;
  }
}

@media (min-width: 680px) {
  .el-space__item {
    max-width: 300px;
  }
}

@media (min-width: 530px) and (max-width: 680px) {
  .el-space__item {
    max-width: calc(50% - 16px);
  }
}

.el-main {
  display: -webkit-box;
  box-sizing: border-box;
  overflow: hidden;
  min-width: 200px;

  .el-space[data-inOut] {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
  }

  .el-space__item {
    flex: 1;
    min-width: 230px;
  }

  .el-card {
    width: 100%;
    min-width: 200px;
    cursor: pointer;
    user-select: none;
    border-radius: 8px;

    .el-card__body {
      display: flex;
      align-items: center;

      i {
        margin-right: 20px;
        font-size: 1.6rem;
        color: var(--el-color-primary);
      }

      .el-space {

        .el-space__item {
          max-width: 100%;
          min-width: 6rem;
          text-align: left;
        }

        .title {
          font-size: var(--el-font-size-medium);
          color: var(--el-text-color-primary);
        }

        .description {
          font-size: var(--el-font-size-base);
          color: var(--el-text-color-placeholder);
        }
      }
    }
  }
}
</style>
