import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, {
    path: '/onlineQuery',
    name: '在线查询',
    component: () => import( '../views/onlineQuery.vue')
  }, {
    path: '/parsingVideo',
    name: '视频解析',
    component: () => import( '../views/parsingVideo.vue')
  }, {
    path: '/deDuplication',
    name: '数据拆分',
    component: () => import( '../views/deDuplication.vue')
  }, {
    path: '/:pathMatch(.*)*',
    name: '未找到此页面',
    component: () => import('../views/notFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
