// 存储localStorage
const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}

// 获取localStorage
const getStore = name => {
    if (!name) return
    return window.localStorage.getItem(name)
}

// 删除localStorage
const removeStore = name => {
    if (!name) return
    window.localStorage.removeItem(name)
}

// 设置cookie
const setCookie = (name, value, day) => {
    if (!name) return
    if (day) {
        let date = new Date();
        date.setTime(date.getTime() + day * 24 * 60 * 60 * 1000);
        document.cookie = name + '=' + value + ';expires=' + date.toUTCString();
    } else {
        document.cookie = name + '=' + value;
    }
}

// 获取cookie
const getCookie = name => {
    if (!name) return
    let reg = new RegExp(name + '=([^;]*)(;|$)');
    let arr = document.cookie.match(reg);
    if (arr) {
        return arr[1];
    } else {
        return null;
    }
}

// 删除cookie
const removeCookie = name => {
    if (!name) return
    setCookie(name, '', -1);
}

const findEl = (el, attr, target) => {
    if ([...el[attr]].indexOf(target) == -1) {
        return findEl(el.parentNode, attr, target);
    } else {
        return el;
    }
}

const throttle = (f, wait) => {
    let timer;
    return (...args) => {
        if (timer) {
            return;
        }
        timer = setTimeout(() => {
            f(...args);
            timer = null;
        }, wait);
    };
}

export {
    setStore,
    getStore,
    removeStore,
    setCookie,
    getCookie,
    removeCookie,
    findEl,
    throttle
}